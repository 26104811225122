import React from 'react';
import { Grommet, Box, Heading } from 'grommet';
import Lottie from 'react-lottie';
import animationData from '../assets/lotties/success.json'
import { Link } from 'gatsby';

const SuccessPage = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      fps: 15
    }
  };

  return (
    <Box align="center" justify="center" fill="vertical" margin="10%">
      <Box width="medium">
        <Lottie options={defaultOptions} height={100} width={100} />
      </Box>
      <Heading level={3} margin="none">Submission received</Heading>
      <Link to="/">Return to Homepage</Link>
    </Box >
  );
};

export default SuccessPage;
